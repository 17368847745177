<template>
  <router-view />
</template>

<script>

</script>

<style>
html{
  background-color: #E3E3E3;
}
</style>
